import * as React from "react";
import { Link } from "gatsby";
import "../styles/utilities.scss";
import "../styles/index.scss";
// styles
const pageStyles = {
	color: "rgb(231, 231, 231)",
	padding: "96px",
	fontFamily: "-apple-system, Roboto, sans-serif, serif",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: "100%",
	width: "100%",
	backgroundColor: "rgb(27, 192, 210)",
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
};

const paragraphStyles = {
	marginBottom: 48,
};
const codeStyles = {
	color: "#8A6534",
	padding: 4,
	backgroundColor: "#FFF4DB",
	fontSize: "1.25rem",
	borderRadius: 4,
};

// markup
const NotFoundPage = () => {
	return (
		<main style={pageStyles}>
			<title>Not found</title>
			<h1 style={headingStyles}>420 Error: Page Not Found</h1>
			<p style={paragraphStyles}>
				Computer Says No. Sorry{" "}
				<span role="img" aria-label="Pensive emoji">
					😔
				</span>{" "}
				we couldn’t find what you were looking for.
				<br />
				{process.env.NODE_ENV === "development" ? (
					<>
						<br />
						Try creating a page in{" "}
						<code style={codeStyles}>src/pages/</code>.
						<br />
					</>
				) : null}
				<br />
				<Link to="/">Go to boygolden.ca</Link>.
				<br />
				<br />
				<a href="https://www.churchofbetterdaze.com">
					Go to churchofbetterdaze.com
				</a>
				.
			</p>
		</main>
	);
};

export default NotFoundPage;
